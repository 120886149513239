import { useState } from "react";
import Calendar from "react-calendar";
import { CiCalendar } from "react-icons/ci";
import { EVENT_TYPES } from "../../../../../../utils/constants";
import { getTypeFilterButtons } from "./filterDropdownFunctions";
import { getFormattedDate } from "../../../List/ListElements/eventCardFunctions";
import "./filterMenuDropDown.css"
import "./calendarFilter.css";

export default function FilterMenuDropDown({ 
    isActive, activeTypeFilters, typeFilterToggler, dateFilterSetter, currentDateFilter 
}) {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const handleCalendarToggle = () => {
        setIsCalendarOpen(!isCalendarOpen);
    }

    const handleDateFilterReset = () => {
        dateFilterSetter(null);
    }

    const calendar = isCalendarOpen ? (
        <Calendar onChange={dateFilterSetter} value={currentDateFilter} />
    ) : [];

    let menu = []
    if (isActive) {
        const typeFilterButtons = getTypeFilterButtons(EVENT_TYPES, typeFilterToggler, activeTypeFilters);

        menu = (
            <div className="filter-dropdown-container">
                <div className="filter-dropdown-button-container">
                    {typeFilterButtons}
                </div>

                <div className="filter-dropdown-button-container">
                    <button className={`filter-dropdown-button filter-dropdown-date-button ${currentDateFilter ? "filter-dropdown-button-active" : ""}`}
                        onClick={handleCalendarToggle}>
                        {currentDateFilter ? (
                            <>
                                <p>{getFormattedDate(currentDateFilter)}</p>
                                <CiCalendar />
                            </>
                            ) : "Select Date"}
                    </button>

                    {currentDateFilter ? (
                        <button className="filter-dropdown-button filter-dropdown-reset-date-button"
                            onClick={handleDateFilterReset}>
                            Reset Date
                        </button>
                    ) : []}
                </div>

                {calendar}
            </div>
        );
    }
    return menu
}
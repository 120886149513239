/**
 *  This component represents the Wisest Tool page. Here users can upload their files 
 *  (accpted types: .txt, .tex, .pdf) and upload them to get them appraised by the AI.
 *  This component is connected to a Flask python backend that returns the AI's results.
 *  
 *  Note for TA:
 *      Right now the AI has not been provided to us by the client. We are using a fixed sample result 
 *      and our python backend is returning it regardless of the file being uploaded.
 * 
 * 
 *  @author Alessandro Marmi, Aliyah James, Elena Scobici, Cassandra Tin Kwon Yuen
 */


import "./Wisest.css";
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IoIosCloudUpload } from 'react-icons/io'
import {
  ToolBoxContainer, ToolBox, LogoContainer, SubHeading, DragDrop,
  DragDropMessage, UploadContainer, SubmitContainer,
  EmptyDiv
} from './WisestElements'
import UploadedFileContainerComponent from "./UploadedFileContainer";
import { AuthContext } from "../AuthenticationPages/AuthContext";
import { BACKEND_URL } from "../../utils/constants";

const WisestTool = () => {
  // Boolean variable that keeps track of whether the screem is in
  // desktop view (>= 1000px width, true) or mobile view (false).
  let currentlyLargeScreen = window.innerWidth >= 1000;

  const [fileUploaded, setFileUploaded] = useState(false); // keeps track of whether a file is uploaded
  const [File, setFile] = useState(''); // stores the file
  const { isLoggedIn, userName, token } = useContext(AuthContext);

  // Keep track of the text on the drag & drop component
  const desktopBrowseText = 'Drag & drop files or click to browse';
  const mobileBrowseText = 'Browse files';
  const [browseText, setBrowseText] = useState(currentlyLargeScreen ? desktopBrowseText : mobileBrowseText);


  // list of names of uploaded files
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleUploadBoxClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();  // allows to drag elements
  };

  useEffect(() => {
    if (isLoggedIn) {
      const fetchUploadedFiles = async () => {
        try {
          const encodedUserName = encodeURIComponent(userName);
          const url = `${BACKEND_URL}/user-uploads?userName=${encodedUserName}`;
          const response = await fetch(url, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          const fileNames = data.map(upload => upload.file_name);
          setUploadedFiles(fileNames);
        } catch (error) {
          console.error('Error fetching user uploads:', error);
        }
      };

      fetchUploadedFiles();
    }
  }, [isLoggedIn, userName]);


  const handleDrop = (e) => {
    e.preventDefault();
    let files = e.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileType = fileName.split('.').pop().toLowerCase();

      if (fileType === 'pdf' || fileType === 'tex' || fileType === 'txt') {
        setFileUploaded(file);  // Update the fileUploaded state
        alert('Success!')
      } else {
        alert('Invalid file type. Please upload .pdf, .tex, or .txt files.');
      }
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileType = fileName.split('.').pop().toLowerCase();

      if (['pdf', 'txt', 'tex'].includes(fileType)) {
        setFileUploaded(file); //to display the name of the file when uploaded 
        alert('Success!')
      } else {
        alert('Invalid file type. Please upload .pdf, .tex, or .txt files only.');
      }
    }
  };



  const SubmitFileButton = () => {
    const navigate = useNavigate();

    const handleClick = async () => {

      if (!fileUploaded) {
        alert('Upload a valid file first!');
        return;
      }

      if (!isLoggedIn) {
        alert('You are not Logged In!');
        return;
      }

      // Redirect to loading page
      navigate('/loading');

      const formData = new FormData();
      const requestId = Math.floor(10000000 + Math.random() * 90000000);


      formData.append('file', fileUploaded)
      formData.append('email', userName)
      formData.append('request_id', requestId);

      const response_wisest_back_upload = await fetch(`${BACKEND_URL}/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Replace `token` with your actual token variable
          // 'Content-Type': 'multipart/form-data', // Fetch automatically handles the correct content-type for FormData
        },
        body: formData, // FormData object is used for file uploads
      });

      const data = await response_wisest_back_upload.json();

      if (data.upload_message !== "file uploaded successfully") return;


      try {
        const response = await fetch('https://ai.ls3.rnet.torontomu.ca/wisest/get-pdf-data-initially', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.queue >= 1) {
          console.log('File is in the queue:', data.queue);

          // Send queue number to the loading page with a random query parameter to force reload
          navigate('/loading', { state: { queue: data.queue }, replace: true });
        } else {
          console.log('File is not in queue');
          navigate('/loading', { state: { queue: "file not in queue" }, replace: true });
        }
      } catch (error) {
        console.error('Error checking PDF data:', error);
      }



      // try {
      //   // Upload the file
      //   fetch('https://ai.ls3.rnet.torontomu.ca/wisest/process_pdf', {
      //     method: 'POST',
      //     body: formData,
      //   });
      // } catch (error) {
      //   console.error('Error processing file on AI endpoint:', error);
      // }

      let retryInterval;

      const checkPdfData = async () => {
        try {

          const response = await fetch('https://ai.ls3.rnet.torontomu.ca/wisest/get-pdf-data', {
            method: 'POST',
            body: formData
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();

          if (data && data.final_result) {
            console.log('File uploaded successfully:', data.final_result);

            // Once the value is returned, navigate to another page or update state
            navigate('/Results', { state: { result: data.final_result, fileUploaded, request_id: requestId } });

            // Clear the retry interval once we successfully get the data
            clearInterval(retryInterval);
          } else if (data.queue >= 1) {
            console.log('File is in the queue:', data.queue);

            // Send queue number to the loading page
            navigate('/loading', { state: { queue: data.queue }, replace: true });
          } else {
            console.log('File is not in the queue');

            // Send queue number to the loading page
            navigate('/loading', { state: { queue: "file not in the queue" }, replace: true });
          }
        } catch (error) {
          console.error('Error checking PDF data:', error);
        }
      };

      // Start checking every 10 seconds
      retryInterval = setInterval(checkPdfData, 15000);
    };

    return <button onClick={handleClick} className="submit-button"> Submit </button>;
  };

  // const SubmitFileButton = () => {
  //   const navigate = useNavigate();

  //   const handleClick = async () => {
  //     if (!fileUploaded) {
  //       alert('Upload a valid file first!');
  //       return;
  //     }
  //     if (!isLoggedIn) {
  //       alert('You are not Logged In!');
  //       return;
  //     }

  //     // Redirect to loading page
  //     navigate('/loading');

  //     const formData = new FormData();
  //     // formData.append('pdf_file', fileUploaded);
  //     formData.append('file', fileUploaded)
  //     formData.append('email', userName)

  //     try {
  //       // Upload PDF to the wisest backend
  //       const response_wisest_back_upload = await axios.post(
  //         `${BACKEND_URL}/upload`,
  //         formData,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`, // Replace `token` with your actual token variable
  //             'Content-Type': 'multipart/form-data', // This is usually required for file uploads
  //           },
  //         }
  //       );

  //       const result_wisest_back_upload = await response_wisest_back_upload
  //       console.log(`response_wisest_back_upload: ${JSON.stringify(result_wisest_back_upload)}`)
  //       // Check if the upload was successful
  //       if (response_wisest_back_upload.data.upload_message === "file uploaded successfully") {
  //         // If successful, proceed with processing the file on the AI endpoint
  //         try {
  //           const response = await fetch('https://ai.ls3.rnet.torontomu.ca/wisest/process_pdf2', { //ideally this should be called with response_wisest_back_upload. so it should be in the backend of response_wisest_back_upload
  //             method: 'POST',
  //             body: formData,
  //           });

  //           if (!response.ok) {
  //             throw new Error(`HTTP error! status: ${response.status}`);
  //           }

  //           const result = await response.json();
  //           console.log('File uploaded successfully:', result);

  //           // Navigate to the Results page with the returned result
  //           navigate('/Results', { state: { result, fileUploaded: true } });
  //         } catch (error) {
  //           console.error('Error processing file on AI endpoint:', error);
  //         }
  //       } else {
  //         console.error('File upload to wisest backend failed:', response_wisest_back_upload.statusText);
  //       }
  //     } catch (error) {
  //       console.error('Error uploading file to wisest backend:', error);
  //     }
  //   };

  //   return <button onClick={handleClick} className="submit-button"> Submit </button>;
  // };


  // Switch the screen view mode.
  function handleResize() {
    if (window.innerWidth < 1000 === currentlyLargeScreen) {
      currentlyLargeScreen = !currentlyLargeScreen;
      setBrowseText(currentlyLargeScreen ? desktopBrowseText : mobileBrowseText);
    }
  }

  // Listen for window resize.
  window.addEventListener('resize', handleResize);

  return (
    <>
      <ToolBoxContainer>
        <ToolBox>
          <LogoContainer>Upload Files to WISEST</LogoContainer>
          <SubHeading>(pdf, txt or tex)</SubHeading>
          <UploadContainer>
            <DragDrop onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleUploadBoxClick}>
              {(!fileUploaded || currentlyLargeScreen) &&
                <IoIosCloudUpload size={80} color='var(--middle-purple)' />
              }
              {fileUploaded && !currentlyLargeScreen &&
                <UploadedFileContainerComponent fileName={fileUploaded.name}></UploadedFileContainerComponent>
              }
              <DragDropMessage>{browseText}</DragDropMessage>
            </DragDrop>
            <SubmitContainer>
              <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileUpload} data-testid="file-input" />
              {fileUploaded && currentlyLargeScreen && (
                <UploadedFileContainerComponent fileName={fileUploaded.name}></UploadedFileContainerComponent>
              )}
              {/* Empty tag to ensure that the submit button is 
                always at the bottom of the screen. */}
              <EmptyDiv></EmptyDiv>
              <SubmitFileButton />
            </SubmitContainer>
          </UploadContainer>
        </ToolBox>
      </ToolBoxContainer>
    </>
  );
}
export default WisestTool;

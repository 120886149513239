
export function getTypeFilterButtons(typeList, handleTypeFilterClick, activeTypeFilters) {
    const typeFilters = typeList.map((type) => {
        const isActived = activeTypeFilters.includes(type);
        const buttonText = isActived ? (
            <>
                <p>{type}</p>
                <p>{isActived ? "✓" : ""}</p>
            </>
        ) : (
            <p>{type}</p>
        )

        return (
            <button id={type} className={`filter-dropdown-button ${isActived ? "filter-dropdown-button-active" : ""}`}
                onClick={handleTypeFilterClick}>
                {buttonText}
            </button>
        )
    });
    return typeFilters;
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --purple: #7986BB;
  --dark-purple: #555f87;
  --teal: #72BDA9;
  --yellow: #EBDF95;
  --dark-yellow: #BDB16C;
  --black: #262A35;
  --light-grey: #FAFAFE;
  --grey: #E9EDF0;
  --light-teal: #d3ebe5ff;
  --dark-teal: #008B66;
  --orange: #F0771C;
}

#root {
    height: 100vh;
}

* {
    margin: 0;
    padding: 0;
}


body {
    min-height: 100vh;
    background-color: #fff; 
    font-family: Mulish;
    font-weight: 400;
}

#logo {
    width: auto;
    height: 90px;
}

#log-in, #dashboard {
    right: 7rem; /* log in and dashboard buttons offset from sign up and log out buttons */
}
#wisest-tool{
    right: 16rem; /* log in and dashboard buttons offset from sign up and log out buttons */
}


.box { /* for div elements that require positioning */
    position: relative;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,qBAAqB;EACrB,eAAe;EACf,uBAAuB;EACvB,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,UAAU;AACd;;;AAGA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW,EAAE,yEAAyE;AAC1F;AACA;IACI,YAAY,EAAE,yEAAyE;AAC3F;;;AAGA,OAAO,8CAA8C;IACjD,kBAAkB;AACtB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&display=swap');\n\n:root {\n  --purple: #7986BB;\n  --dark-purple: #555f87;\n  --teal: #72BDA9;\n  --yellow: #EBDF95;\n  --dark-yellow: #BDB16C;\n  --black: #262A35;\n  --light-grey: #FAFAFE;\n  --grey: #E9EDF0;\n  --light-teal: #d3ebe5ff;\n  --dark-teal: #008B66;\n  --orange: #F0771C;\n}\n\n#root {\n    height: 100vh;\n}\n\n* {\n    margin: 0;\n    padding: 0;\n}\n\n\nbody {\n    min-height: 100vh;\n    background-color: #fff; \n    font-family: Mulish;\n    font-weight: 400;\n}\n\n#logo {\n    width: auto;\n    height: 90px;\n}\n\n#log-in, #dashboard {\n    right: 7rem; /* log in and dashboard buttons offset from sign up and log out buttons */\n}\n#wisest-tool{\n    right: 16rem; /* log in and dashboard buttons offset from sign up and log out buttons */\n}\n\n\n.box { /* for div elements that require positioning */\n    position: relative;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

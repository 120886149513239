// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events-pagination-container {
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: center;
    border-radius: 1em;
    padding: 0.25em 0;
    margin: 1em 3em 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);;
}

.events-pagination-button {
    background-color: #fff;
    padding: 0.25em 0.5em;
    border-radius: 0.25em;
    border: none;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: #72bda9;
    font-size: calc(0.5rem + 0.5vw);
    font-weight: bolder;
    transition: all 0.2s ease-in-out;
}

.events-pagination-button:hover {
    background-color: #72bda9;
    color: #fff;
}

.events-pagination-page-button {
    color: black;
}

.events-pagination-page-button-active {
    background-color: #72bda9;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/EventsPage/EventsPageElements/Pagination/eventsPagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,sCAAsC;AAC1C;;AAEA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,qBAAqB;IACrB,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,cAAc;IACd,+BAA+B;IAC/B,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".events-pagination-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    gap: 1em;\r\n    align-items: center;\r\n    border-radius: 1em;\r\n    padding: 0.25em 0;\r\n    margin: 1em 3em 0;\r\n    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);;\r\n}\r\n\r\n.events-pagination-button {\r\n    background-color: #fff;\r\n    padding: 0.25em 0.5em;\r\n    border-radius: 0.25em;\r\n    border: none;\r\n    width: fit-content;\r\n    height: fit-content;\r\n    color: #72bda9;\r\n    font-size: calc(0.5rem + 0.5vw);\r\n    font-weight: bolder;\r\n    transition: all 0.2s ease-in-out;\r\n}\r\n\r\n.events-pagination-button:hover {\r\n    background-color: #72bda9;\r\n    color: #fff;\r\n}\r\n\r\n.events-pagination-page-button {\r\n    color: black;\r\n}\r\n\r\n.events-pagination-page-button-active {\r\n    background-color: #72bda9;\r\n    color: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.lesson-card-header-container-main,
.lesson-card-header-container-optional {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    width: 100%;
    border-radius: 0.25em;
}

.lesson-card-header-container-main {
    background-color: #d3ebe5;
}

.lesson-card-header-container-optional {
    background-color: #e5e5e5;
}

.lesson-card-header-right-container {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 5em;
}


.lesson-card-header-checkmark,
.lesson-card-header-checkmark-complete {
    border-radius: 1em;
    padding: 0.3em;
    aspect-ratio: 1/1;
    color: white;
    font-size: calc(1rem + 0.5vw);
    margin-right: 0.5rem;
}

.lesson-card-header-checkmark {
    background-color: #aaa;
}

.lesson-card-header-checkmark-complete {
    background-color: #00ba00;
}

.lesson-card-header-dropdown-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    font-size: calc(1.5rem + 0.6vw);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/TrainingPage/TrainingElements/Lessons/Card/CardElements/lessonCardHeader.css"],"names":[],"mappings":";AACA;;IAEI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;IACnB,UAAU;AACd;;;AAGA;;IAEI,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,YAAY;IACZ,6BAA6B;IAC7B,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,6BAA6B;IAC7B,+BAA+B;IAC/B,eAAe;AACnB","sourcesContent":["\r\n.lesson-card-header-container-main,\r\n.lesson-card-header-container-optional {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 0.5em;\r\n    width: 100%;\r\n    border-radius: 0.25em;\r\n}\r\n\r\n.lesson-card-header-container-main {\r\n    background-color: #d3ebe5;\r\n}\r\n\r\n.lesson-card-header-container-optional {\r\n    background-color: #e5e5e5;\r\n}\r\n\r\n.lesson-card-header-right-container {\r\n    display: flex;\r\n    justify-content: end;\r\n    align-items: center;\r\n    width: 5em;\r\n}\r\n\r\n\r\n.lesson-card-header-checkmark,\r\n.lesson-card-header-checkmark-complete {\r\n    border-radius: 1em;\r\n    padding: 0.3em;\r\n    aspect-ratio: 1/1;\r\n    color: white;\r\n    font-size: calc(1rem + 0.5vw);\r\n    margin-right: 0.5rem;\r\n}\r\n\r\n.lesson-card-header-checkmark {\r\n    background-color: #aaa;\r\n}\r\n\r\n.lesson-card-header-checkmark-complete {\r\n    background-color: #00ba00;\r\n}\r\n\r\n.lesson-card-header-dropdown-button {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border: none;\r\n    background-color: transparent;\r\n    font-size: calc(1.5rem + 0.6vw);\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

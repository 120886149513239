// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/components/EventsPage/eventsPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".events-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n    min-height: 100vh;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

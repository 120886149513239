// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.lesson-card-dropdown-container {
    margin: 0 3%;
    transition: 0.3s ease-out;
}

.lesson-card-complete-button {
    border: none;
    border-radius: 10px;
    background-color: #fee599;
    padding: 0.5em 1em;
    font-size: 1.25rem;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    margin-bottom: 2rem;
}

.lesson-card-complete-button:hover {
    background-color: #fddc87;
    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/components/TrainingPage/TrainingElements/Lessons/Card/CardElements/lessonCardDropdown.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":["\r\n.lesson-card-dropdown-container {\r\n    margin: 0 3%;\r\n    transition: 0.3s ease-out;\r\n}\r\n\r\n.lesson-card-complete-button {\r\n    border: none;\r\n    border-radius: 10px;\r\n    background-color: #fee599;\r\n    padding: 0.5em 1em;\r\n    font-size: 1.25rem;\r\n    font-weight: bold;\r\n    transition: all 0.2s ease-in-out;\r\n    margin-bottom: 2rem;\r\n}\r\n\r\n.lesson-card-complete-button:hover {\r\n    background-color: #fddc87;\r\n    cursor: pointer;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useState } from "react";
import FilterMenuDropDown from "./FilterMenuElements/FilterMenuDropDown";
import { IoFilter } from "react-icons/io5";

export default function FilterMenu({ 
    activeTypeFilters, typeFilterToggler, dateFilterSetter, currentDateFilter 
}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuButton = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const hasActiveFilters = activeTypeFilters.length > 0 || currentDateFilter;

    return (
        <div className="events-filter-menu-container">
            <button className="events-filter-menu-button"
                onClick={handleMenuButton}
                >
                FILTER
                {hasActiveFilters && <IoFilter id="filter-icon"/>}
                
            </button>
            <FilterMenuDropDown isActive={isMenuOpen} 
                activeTypeFilters={activeTypeFilters}
                typeFilterToggler={typeFilterToggler} 
                dateFilterSetter={dateFilterSetter}
                currentDateFilter={currentDateFilter}
                />
        </div>
    );
}
/**
    Create a React component representing all teams 
    on the About Us Page. This contains all team sections
    and each member's relevant information from constants.js.

    @author Cassandra Tin Kwon Yuen, Sergio Perez
*/

import TeamCard from "./TeamSection"
import TeamList from "./TeamList";
import { ALL_TEAMS } from "../../../utils/constants"
import { useEffect, useState } from 'react'

export default function Teams() {
    const [collaboratorsNames, setCollaboratorsNames] = useState([]);

    function createObjectsArray(collaboratorsNames) {
        // Initialize an empty array to store the result
        let result = [];
    
        // Iterate through each name in collaboratorsNames
        collaboratorsNames.forEach(name => {
            // Create an object with "name" set to the current name and "description" set to null
            let obj = {
                name: name,
                description: null
            };
            // Push the created object into the result array
            result.push(obj);
        });
    
        // Return the final array of objects
        return result;
    }

    useEffect(() => {
        const fetchData = async () => {

            const SPREADSHEET_ID = '1QCqj31HJrR7iC-dbNodtYdC2oTuNp53uezd4MRkDv70';
            const RANGE = 'Sheet1'; // Replace with your sheet range
            const API_KEY = 'AIzaSyBZCiajRBHjbdd_YCYoCtde50Y0iLnOgq8'
            
            const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`;

            try {
                const response = await fetch(url)

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setCollaboratorsNames(data.values);

            } catch (error) {
                console.error('Error fetching Google Sheets data:', error);
            }
        };

        fetchData();
    }, []);

    const collaboratorsNamesAndDescription = createObjectsArray(collaboratorsNames)
    const TEAMS = ALL_TEAMS;
    TEAMS['Collaborators'] = collaboratorsNamesAndDescription

    const teams = []
    for (const teamName in TEAMS) {
        const teamMembers = TEAMS[teamName];
        const hasPicture = teamMembers.some(member => member.picture);

        const teamComponent = hasPicture ? (
            <TeamCard teamName={teamName} teamMembers={teamMembers} />
        ) : (
            <TeamList teamName={teamName} teamMembers={teamMembers} />
        );

        teams.push(teamComponent);
    }

    return (
        <div className="teams-container">
            { teams }
        </div>
    )
}
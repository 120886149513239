
export function getEventColor(type) {
    return (
        type === "Workshop" ? "#f39147"
        : type === "Webinar" ? "#72bda9"
        : type === "Training" ? "#7986bb"
        : "#a5a5a5"
    )
}

export function getFormattedDate(date) {
    const month = date.toLocaleString('default', { month: 'short' })
    const day = date.getDate()
    const year = date.getFullYear()
    return `${month} ${day}, ${year}`
}
/**
 *  This component represents the Results page. Users are redirected here when the 
 *  backend has finished processing the post request (and has "appraised" their 
 *  systematic review). In this page the results are displayed and users have the
 *  chance of exporting them using the button at the bottom. The user can also view
 *  and export the document that they uploaded.
 *  The results contain a summary page and detailed reports of the ROBIS and
 *  AMSTAR 2 results. The user can leave feedback in the detailed reports as well.
 *  These components will be generated dynamically from the results of the AI tool.
 * 
 *  Note:
 *      Since the WISEST tool has not been ingetrated yet, the python backend
 *      will always return the same sample results. 
 * 
 *  @author Alessandro Marmi, Aliyah James, Cassandra Tin Kwon Yuen, Sergio Perez
 */

import { useState, useEffect } from 'react';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import { Card, Heading, ExportButton, ResultContainer, ButtonContainer } from "./ResultsPageElements";
import "./ResultsPage.css";
import { HiUser } from 'react-icons/hi2'
import { IoHandLeft } from 'react-icons/io5'
import { RiEqualizerFill } from 'react-icons/ri'
import { BsClipboard2CheckFill } from 'react-icons/bs'
import Logo from "../../images/logo_wide2.png";
import leftArrow from '../../images/left_arrow.svg';
import rightArrow from '../../images/right-arrow.svg';
import SummaryPage from './SummaryPage';
import SideViewButton from './SideViewButton';
import SwapButton from './SwapButton';
import DetailedPage from './DetailedPage';
import useWindowResize from './useWindowResize';
import ReactDOMServer from 'react-dom/server';
import { AuthContext } from "../AuthenticationPages/AuthContext"
import { BACKEND_URL } from '../../utils/constants';
import testPDF from '../../temporary_resources/adam.azizi@torontomu.ca-7_Hansen_2017_1.pdf'

// Apply the class depending on the row in the PICO Eligibility Criteria chart (table2)
const getClassName = (key) => {
  if (key.includes("Number of primary")) {
    return "grey-box"
  } else if (key.includes("ROBIS domain")) {
    return "yellow-box"
  } else if (key.includes("Overall ROBIS")) {
    return "light-teal-box"
  } else if (key.includes("ROBIS Overall")) {
    return "teal-box"
  } else if (key.includes("AMSTAR")) {
    return "purple-box"
  }
  return ""
};

// Circle colour depending on the heading
const getCircleColour = (key) => {
  if (key.includes("Participants")) {
    return "#f49c5aff"
  } else if (key.includes("Interventions")) {
    return "#72bda9ff"
  } else if (key.includes("Comparators")) {
    return "#7986bbff"
  } else if (key.includes("Outcomes")) {
    return "#262a35ff"
  }
  return ""
};

// Icon source depending on the heading
const getIcon = (key) => {
  if (key.includes("Participants")) {
    return <HiUser />
  } else if (key.includes("Interventions")) {
    return <IoHandLeft />
  } else if (key.includes("Comparators")) {
    return <RiEqualizerFill />
  } else if (key.includes("Outcomes")) {
    return <BsClipboard2CheckFill />
  }
  return <></>
};

// Add line break after / in the given text
const insertLineBreaks = (text) => {
  const modifiedText = text.replace('/', '/\n');
  return <div dangerouslySetInnerHTML={{ __html: modifiedText }} />;
};


const RenderSummary = (summary) => {
  return (
    <div id="summary">
      <div className="report-title">WISEST Summary Report</div>
      {/* TABLE1 */}
      <div className="grid1">
        {/* Top heading */}
        <div className="table1-header">
          <h2>{summary.table1[0]}</h2>
        </div>
        {summary.table1[1].map((item, index) => (
          <div className="table1-row">
            {/* Left hand side headings */}
            <div className="table1-left" key={index}>{item[0]}</div>
            {/* Right hand side values */}
            <div className="table1-right">{item[1]}</div>
          </div>
        ))}

      </div>

      {/* TABLE2 */}
      <div className="report-subheading"> Quality Assessment Results</div>
      <div id="quality">
        {summary.table2.map((item, index) => (
          <div key={index} className={`grid2 ${getClassName(item[0])}`}>
            {/* Left hand side bolded heading */}
            <div className="table2-left">
              <p className='table2-text'>{item[0]}</p>
            </div>

            {Array.isArray(item[1]) ? (
              // If the value is an array (ROBIS domain judgments or Overall ROBIS judgments)
              <div className={"table2-row"}>
                {item[1].map((subItem, subIndex) => (
                  <div key={subIndex} className={"table2-subrow"}>
                    {/* Middle column */}
                    <div className="table2-middle">
                      <p className='table2-text'>{subItem[0]}</p></div>
                    {/* Right column value */}
                    <div className="table2-right">
                      <p className='table2-text'>{subItem[1]}</p></div>
                  </div>
                ))}
              </div>
            ) : (
              // If the value is not an array (right column value)
              <div className="table2-right">
                <p className='table2-text'>{item[1]}</p>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* TABLE3 */}
      <div id="icon-grid-container">
        <div className="icon-grid">
          {summary.table3.map((item, index) => (
            <div className="icon-container">
              {/* Conditionally change icon depending on the heading (item[0])*/}
              <div className="circle" style={{ backgroundColor: getCircleColour(item[0]) }}>
                <div className="report-icon">{getIcon(item[0])}</div>
              </div>
              {/* Headings */}
              <div key={index} className="icon-heading">{insertLineBreaks(item[0])}</div>
              {/* Values */}
              <div className="icon-text">{item[1]}</div>
            </div>
          ))}
        </div>
      </div>
      {/* CONCLUSION */}
      <div className="conclusions">
        <div className="report-subheading">Conclusions</div>
        <div className="conclusion-text">{summary.conclusion}</div>
      </div>
      <div className="report-logo" >
        <img src={Logo}></img>
      </div>
      <hr class="report-line"></hr>
    </div>
  )

}

// Export the results of the AI tool
// Generates the component in the DOM, takes a "screenshot",
// then creates the PDF.
const exportResult = async (summary, detailed) => {
  const fixedWidth = '210mm';
  const element = document.createElement('div');
  element.style.width = fixedWidth;
  element.style.position = 'absolute';
  element.style.left = '-9999px'; // shift off the screen

  // Create a div for SummaryPage
  const summaryDiv = document.createElement('div');
  summaryDiv.innerHTML = ReactDOMServer.renderToString(<SummaryPage summary={summary} />);
  element.appendChild(summaryDiv);

  // Create a div for DetailedPage
  const detailedDiv = document.createElement('div');
  element.appendChild(detailedDiv);

  document.body.appendChild(element);
  await new Promise(requestAnimationFrame);

  await new Promise(resolve => setTimeout(resolve, 500));

  detailedDiv.innerHTML = ReactDOMServer.renderToString(<DetailedPage detailed={detailed} />);

  await new Promise(requestAnimationFrame);

  await new Promise(resolve => setTimeout(resolve, 500));

  const detailedExportElement = document.getElementById("detailed-export");
  const detailedReportContainers = detailedExportElement.getElementsByClassName("detailed-container-export");

  // Height of viewport (based on the contents of summary and detailed reports)
  let height = 0;
  for (let container of detailedReportContainers) {
    container.style.paddingTop = "3mm";
    let containerHeight = container.offsetHeight;
    height += containerHeight;
  }
  height = document.getElementById("detailed-export").offsetHeight;
  console.log(height);
  height += element.offsetHeight;
  console.log("Height: ", height)
  height += 1000;


  const pdfOptions = {
    margin: 0,
    filename: 'wisest-result.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, scrollY: 0, height: height },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  };
  element.style.left = '0'; // shift back so PDF positioning is correct
  html2pdf(element, pdfOptions);
  document.body.removeChild(element);
};



const ResultsPage = () => {
  const location = useLocation();
  const width = useWindowResize();
  const [displayUpload, setDisplayUpload] = useState(false)
  const [displayReport, setDisplayReport] = useState(true)
  const [swapText, setSwapText] = useState("WISEST Report")
  const result = location.state?.result || "No data available";
  const file = location.state?.fileUploaded || "No file uploaded";
  const request_id = location.state?.request_id || "";

  console.log(`current file name: ${file.name} and current request id: ${request_id}`)
  let summary = result.summary
  let detailed = result.detailed
  console.log(`the result on wisest webpage: ${JSON.stringify(result)}`)
  const { isLoggedIn, userName, token } = useContext(AuthContext);

  const [robisFeedbacks, setRobisFeedbacks] = useState({});
  const [initialRobisFeedbacks, setInitialRobisFeedbacks] = useState({}); // State to store the initial feedbacks

  const [amstarFeedbacks, setAmstarFeedbacks] = useState({});
  const [initialAmstarFeedbacks, setInitialAmstarFeedbacks] = useState({}); // State to store the initial feedbacks

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  const handleRobisFeedbackChange = (key, value) => {
    setRobisFeedbacks({ ...robisFeedbacks, [key]: value });
  };

  const submitRobisFeedback = async (key) => {
    const feedbackData = {
      tool: 'ROBIS',
      question: key,
      filename: file.name, // Assuming file is the state that holds the uploaded file information
      feedback: robisFeedbacks[key],
      email: userName,
    };

    // Send POST request to backend
    try {
      const response = await fetch(`${BACKEND_URL}/submit-feedback-on-results`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
        },
        body: JSON.stringify(feedbackData)
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        setInitialRobisFeedbacks({ ...initialRobisFeedbacks, [key]: robisFeedbacks[key] });
        alert('Feedback submitted successfully!');
      } else {
        console.error('Failed to submit feedback');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
    console.log('Submitting ROBIS feedback:', feedbackData);
  };

  const handleAmstarFeedbackChange = (key, value) => {
    setAmstarFeedbacks({ ...amstarFeedbacks, [key]: value });
  };

  const submitAmstarFeedback = async (key) => {
    const feedbackData = {
      tool: 'AMSTAR',
      question: key,
      filename: file.name, // Assuming file is the state that holds the uploaded file information
      feedback: amstarFeedbacks[key],
      email: userName,
    };
    // Send POST request to backend
    try {
      const response = await fetch(`${BACKEND_URL}/submit-feedback-on-results`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(feedbackData)
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        setInitialAmstarFeedbacks({ ...initialAmstarFeedbacks, [key]: amstarFeedbacks[key] });
        alert('Your feedback was submitted successfully!');
      } else {
        console.error('Failed to submit feedback');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const handleCancel = (key, tool) => {
    if (tool === 'ROBIS') {
      setRobisFeedbacks({ ...robisFeedbacks, [key]: initialRobisFeedbacks[key] });
    } else if (tool === 'AMSTAR') {
      setAmstarFeedbacks({ ...amstarFeedbacks, [key]: initialAmstarFeedbacks[key] });
    }
  };


  useEffect(() => {
    const fetchFeedback = async () => {
      if (!userName || !file.name) {
        console.log("User email or filename is missing");
        return;
      }

      try {
        const response = await fetch(`${BACKEND_URL}/get-feedback-on-results?email=${encodeURIComponent(userName)}&filename=${encodeURIComponent(file.name)}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` // Replace `token` with your actual token variable
          },
        });
        if (response.ok) {
          const feedbackArray = await response.json();

          const feedbackObj = feedbackArray.reduce((obj, item) => {
            // Filter for 'ROBIS' tool and then assign
            if (item.tool === 'ROBIS') {
              obj[item.question] = item.feedback;
            }
            return obj;
          }, {});

          setRobisFeedbacks(feedbackObj);
          setInitialRobisFeedbacks(feedbackObj);

          const amstarFeedbackObj = feedbackArray.reduce((obj, item) => {
            // Filter for 'AMSTAR' tool and then assign
            if (item.tool === 'AMSTAR') {
              obj[item.question] = item.feedback;
            }
            return obj;
          }, {});
          setAmstarFeedbacks(amstarFeedbackObj);
          setInitialAmstarFeedbacks(amstarFeedbackObj);

        } else {
          console.error('Failed to fetch feedback');
        }
      } catch (error) {
        console.error('Error fetching feedback:', error);
      }
    };

    fetchFeedback();
  }, [userName, file]); // Dependency array

  const handleSwap = () => {
    setDisplayUpload(!displayUpload)
    setDisplayReport(!displayReport)
    if (swapText === "WISEST Report") {
      setSwapText("Upload")
    } else {
      setSwapText("WISEST Report")
    }
  }


  // Utility to convert JSON to CSV
  function jsonToCSV(jsonData) {
    let csvData = '';

    Object.keys(jsonData).forEach((category) => {
      csvData += category + "\n";
      csvData += "Question,Response,Quote,Context\n";

      jsonData[category].forEach(item => {
        let row = `"${item.question.replace(/"/g, '""')}",`;
        row += `"${item.response.replace(/"/g, '""')}",`;
        row += `"${item.quote.replace(/"/g, '""')}",`;

        // Check if 'context' exists before adding it to CSV
        if (item.context && item.context !== undefined) {
          row += `"${item.context.replace(/"/g, '""')}"`;
        }

        row += "\n";
        csvData += row;
      });

      csvData += "\n";
    });

    return csvData;
  }

  // function jsonToCSV(jsonData) {
  //   let csvData = '';
  //   // Loop through each category in jsonData
  //   Object.keys(jsonData).forEach((category) => {
  //       // Add a header for the category
  //       csvData += category + "\n";
  //       // Add sub-headers for the details within each category
  //       csvData += "Question,Response,Quote,Context\n";

  //       // Process each item in the category
  //       jsonData[category].forEach(item => {
  //           let row = `"${item.question.replace(/"/g, '""')}",`;
  //           row += `"${item.response.replace(/"/g, '""')}",`;
  //           row += `"${item.quote.replace(/"/g, '""')}",`;
  //           row += `"${item.context.replace(/"/g, '""')}"\n`;
  //           csvData += row;
  //       });

  //       // Add a newline to separate categories
  //       csvData += "\n";
  //   });
  //   return csvData;
  // }

  const exportDetailedAsCSV = (detailed) => {
    // Assuming `detailed` is the detailed_result object from your example
    console.log(`This is the jsonData in page ResultsPage: ${detailed}`)
    console.log(`This is the jsonData stringified in page ResultsPage: ${JSON.stringify(detailed)}`)

    let csvContent = jsonToCSV(detailed);

    // Create a Blob from the CSV string
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a link to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'detailed-results.csv';
    link.click();
    URL.revokeObjectURL(url);
  }


  // Export the upload file
  const exportUpload = () => {
    if (file === "no file uploaded") {
      // If no file is uploaded, you can return null or some other UI element
      return null;
    } else {
      const link = document.createElement('a');
      link.href = `${BACKEND_URL}/uploads/${userName}-${file.name}`;
      link.download = file.name; // Or any other name you wish to give the downloaded file
      link.target = '_blank'; // Open in a new tab
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  };

  var headerColour = getComputedStyle(document.body).getPropertyValue('--light-grey');
  document.body.style.backgroundColor = headerColour;

  // Render the upload and report files
  const renderUpload = () => {
    const updatedRequestId = request_id === "" ? "" : `ID${request_id}-`
    return <iframe
      className="embedded-upload"
      title="Uploaded PDF"
      src={`${BACKEND_URL}/uploads/${userName}-${updatedRequestId}${file.name.replace(/\s+/g, '_')}`}// replaces empty spaces with an underscore for the file name otherwise the iframe wont load it
      // src={`${BACKEND_URL}/uploads/${userName}-${file.name}`}
      height="600px"
      width="100%" />
  }
  console.log(`user name is: `)
  console.log(`user name is: ${file.name}`)
  console.log(`user name is: ${userName}${request_id}-${file.name}`)


  const renderReport = () => {
    if (result === "No data available" || !isLoggedIn) {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh' // This assumes you want to center it in the entire viewport height
        }}>
          No data available
        </div>
      );
    }

    return (
      <div className='wisest-result'>
        {/* <SummaryPage summary={summary} /> */}
        {RenderSummary(summary)}
        {result && (
          <>
            <div className='report-title detailed-subheading'>ROBIS Results</div>
            <div className="detailed-report-container">
              {Object.entries(detailed.ROBIS || {}).map(([key, value]) => (
                <>
                  <div className='accordian'>
                    <details open>
                      <summary className='questions'>
                        <div className='accordian-question-number'>{`Q.${key}`}</div> <div className='accordian-question'>{`${value.question}`}</div>
                      </summary>
                      <p className='accordion-response'><b>Response: </b>{value.response}</p>
                      <p className='accordion-quote'><b>Quote: </b>{value.quote}</p>
                      <details>
                        <summary className='button-feedback'>
                          Feedback
                        </summary>
                        {/* Feedback box goes here  */}
                        <div className='feedback-container'>
                          <div className='feedback-box'>
                            <h3>Feedback</h3>
                            <textarea type='text'
                              className='feedback-text'
                              rows='4'
                              value={robisFeedbacks[key] || ''}
                              onChange={(e) => handleRobisFeedbackChange(key, e.target.value)}
                            />
                            <button
                              className='button feedback-submit'
                              onClick={() => submitRobisFeedback(key)}
                            >
                              Submit
                            </button>
                            <button
                              className='button feedback-cancel'
                              onClick={() => handleCancel(key, 'ROBIS')}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </details>
                    </details>
                  </div>

                </>
              ))}
            </div>

            <div className="report-logo" >
              <img src={Logo}></img>
            </div>
            <hr class="report-line"></hr>


            <div className='report-title detailed-subheading'>AMSTAR Results</div>
            <div className="detailed-report-container">
              {Object.entries(detailed.AMSTAR || {}).map(([key, value]) => (
                <>
                  <div className='accordian'>
                    <details open>
                      <summary className='questions'>
                        <div className='accordian-question-number'>{`Q.${key}`}</div> <div className='accordian-question'>{`${value.question}`}</div>
                      </summary>
                      <p className='accordion-response'><b>Response: </b>{value.response}</p>
                      <p className='accordion-quote'><b>Quote: </b>{value.quote}</p>
                      <details>
                        <summary className='button-feedback'>
                          Feedback
                        </summary>
                        {/* Feedback box goes here  */}
                        <div className='feedback-container'>
                          <div className='feedback-box'>
                            <h3>Feedback</h3>
                            <textarea
                              type='text'
                              className='feedback-text'
                              rows='4'
                              value={amstarFeedbacks[key] || ''}
                              onChange={(e) => handleAmstarFeedbackChange(key, e.target.value)}
                            />
                            <button
                              className='button feedback-submit'
                              onClick={() => submitAmstarFeedback(key)}
                            >
                              Submit
                            </button>
                            <button
                              className='button feedback-cancel'
                              onClick={() => handleCancel(key, 'AMSTAR')}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </details>
                    </details>
                  </div>
                </>
              ))}
            </div>
          </>
        )}
      </div>
    );
  }


  let uploadShowcase = []
  if (displayUpload) {
    uploadShowcase = <div className="upload-container">
      <Heading>Upload</Heading>
      <Card id="uploadCard" className="results-upload-card">
        {renderUpload()}
      </Card>
      <ExportButton onClick={() => exportUpload()}>Export as PDF</ExportButton>
    </div>
  }

  let reportShowcase = []
  if (displayReport) {
    reportShowcase = <div className="report-container">
      <Heading>WISEST Report</Heading>
      <Card id="reportCard" className="results-report-card">
        {renderReport()}
      </Card>
      <div id="export-section">
        <ButtonContainer>
          <ExportButton onClick={() => exportResult(summary, detailed)}>Export as PDF</ExportButton>
          <ExportButton onClick={() => exportDetailedAsCSV(detailed)}>Export as CSV</ExportButton>
        </ButtonContainer>
      </div>

    </div>
  }

  let swapButton = []
  if (width <= 768) {
    swapButton = (
      <div className="results-header-swap-button-container">
        <SwapButton
          onClick={handleSwap}
          text={swapText}
        />
      </div>
    );
  }

  return (
    <>
      {swapButton}

      <ResultContainer>

        {uploadShowcase}

        <SideViewButton sideViewOpenSetter={setDisplayUpload}
          sideViewOpen={displayUpload}
          isActive={width > 768}
          label={<img className="left-arrow" src={displayUpload ? leftArrow : rightArrow} alt="left arrow" />}
        />

        {reportShowcase}

      </ResultContainer>
    </>
  );
};

export default ResultsPage;
